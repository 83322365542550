import { RouteConfig } from 'vue-router';
import { UserTypes } from '@/store/models/user';

export const generalRoutes: Array<RouteConfig> = [
  // {
  //   path: '',
  //   name: 'Home',
  //   component: () =>
  //     import(/* webpackChunkName: "home" */ '@/views/pages/MainPage.vue'),
  //   meta: {
  //     requiresAuth: true
  //     // allowedRoles: [
  //     //   'admin',
  //     //   'dotm_staff',
  //     //   'police',
  //     //   'operational_staff',
  //     //   'vehicle_owner',
  //     //   'gps_service_provider'
  //     // ]
  //   }
  // },
  {
    path: '',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/layouts/FarmFilteredLayout.vue'
      ),
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/views/pages/MainPage.vue'),
        meta: {
          requiresAuth: true
          // allowedRoles: [
          //   'admin',
          //   'dotm_staff',
          //   'police',
          //   'operational_staff',
          //   'vehicle_owner',
          //   'gps_service_provider'
          // ]
        }
      }
    ]
  },
  {
    path: '',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/layouts/FarmFilteredLayout.vue'
      ),
    children: [
      {
        path: 'swipe-map',
        name: 'SwipeMap',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '@/views/pages/SwipeMapView.vue'
          ),
        meta: {
          requiresAuth: true
          // allowedRoles: [
          //   'admin',
          //   'dotm_staff',
          //   'police',
          //   'operational_staff',
          //   'vehicle_owner',
          //   'gps_service_provider'
          // ]
        }
      }
    ]
  },
  {
    path: 'upload',
    name: 'UploadPage',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/components/UploadPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'delete-upload',
    name: 'DeleteUpload',
    component: () =>
      import(
        /* webpackChunkName: "home" */ '@/components/DeleteUploadPage.vue'
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'block',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/layouts/FarmFilteredLayout.vue'
      ),
    children: [
      {
        path: '',
        name: 'BlockPage',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/components/BlockPage.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'summary',
        name: 'BlockSummary',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '@/views/pages/BlockSummary.vue'
          ),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: 'side-by-side',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/layouts/FarmFilteredLayout.vue'
      ),
    children: [
      {
        path: '',
        name: 'SideBySideMap',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/views/pages/SideBySide.vue'),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: 'blogs',
    name: 'Blogs',
    component: () =>
      import(/* webpackChunkName: "blog" */ '@/views/pages/Blogs.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: 'notifications',
    name: 'Notifications',
    component: () =>
      import(
        /* webpackChunkName: "home" */ '@/views/pages/NotificationList.vue'
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'discussions',
    name: 'Discussions',
    component: () =>
      import(
        /* webpackChunkName: "forum" */ '@/views/pages/DiscussionCategory.vue'
      ),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: 'discussions/:category',
    name: 'CategorizedDiscussions',
    component: () =>
      import(
        /* webpackChunkName: "forum" */ '@/views/pages/CategoriedDiscussion.vue'
      ),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: 'discussions/:category/:discussionId',
    name: 'DiscussionDetail',
    component: () =>
      import(
        /* webpackChunkName: "forum" */ '@/views/pages/DiscussionDetail.vue'
      ),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: 'discussions/:category/add',
    name: 'AddDiscussion',
    component: () =>
      import(
        /* webpackChunkName: "forum" */ '@/components/Forms/DiscussionForm.vue'
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'discussions/:category/:discussionId/:forumId/edit',
    name: 'EditForum',
    component: () =>
      import(
        /* webpackChunkName: "forum" */ '@/components/Forms/ForumForm.vue'
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'discussions/:category/:discussionId/:forumId/reply',
    name: 'ReplyForum',
    component: () =>
      import(
        /* webpackChunkName: "forum" */ '@/components/Forms/ForumForm.vue'
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'blogs/:id',
    name: 'ViewBlog',
    component: () =>
      import(/* webpackChunkName: "blog" */ '@/views/pages/BlogDetail.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: 'dashboard',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/layouts/FarmFilteredLayout.vue'
      ),
    children: [
      {
        path: 'yield-by-week',
        name: 'YieldByWeek',
        component: () =>
          import(
            /* webpackChunkName: "general" */ '@/views/pages/YieldByWeek.vue'
          ),
        meta: {
          requiresAuth: true,
          allowedRoles: [
            UserTypes.ADMIN,
            UserTypes.OWNER,
            UserTypes.MANAGER,
            UserTypes.MARKETER
          ]
        }
      },
      {
        path: 'farm-yield-by-week',
        name: 'FarmYieldByWeek',
        component: () =>
          import(
            /* webpackChunkName: "general" */ '@/views/pages/FarmYieldByWeek.vue'
          ),
        meta: {
          requiresAuth: true,
          allowedRoles: [
            UserTypes.ADMIN,
            UserTypes.OWNER,
            UserTypes.MANAGER,
            UserTypes.MARKETER
          ]
        }
      }
    ]
  }
];
