import { RouteConfig } from 'vue-router';

export const authRoutes: Array<RouteConfig> = [
  {
    path: 'activate/:uid/:token',
    name: 'Activate',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Auth/UserActivation.vue'
      )
  },
  // {
  //   path: 'register',
  //   name: 'Register',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "general" */ '@/views/pages/Auth/Register.vue'
  //     )
  // },
  {
    path: 'enroll-admin/:uid/:token',
    name: 'EnrollAdmin',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Auth/EnrollAdmin.vue'
      )
  },
  {
    path: 'enroll-owner/:uid/:token',
    name: 'EnrollOwner',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Auth/EnrollOwner.vue'
      )
  },
  {
    path: 'enroll-manager/:uid/:farmUid/:token',
    name: 'EnrollManager',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Auth/EnrollManager.vue'
      )
  },
  {
    path: 'enroll-consultant/:uid/:farmUid/:token',
    name: 'EnrollConsultant',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Auth/EnrollConsultant.vue'
      )
  },
  {
    path: 'enroll-marketer/:uid/:farmUid/:token',
    name: 'EnrollMarketer',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Auth/EnrollMarketer.vue'
      )
  },
  {
    path: 'password-reset',
    name: 'PasswordReset',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Auth/PasswordReset.vue'
      )
  },
  {
    path: 'password-reset/:uid/:token',
    name: 'ConfirmPasswordReset',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Auth/ConfirmPasswordReset.vue'
      )
  },
  {
    path: 'profile',
    name: 'Profile',
    component: () =>
      import(/* webpackChunkName: "general" */ '@/views/pages/Auth/Profile.vue')
  }
];
