import { UserTypes } from '@/store/models/user';
import { RouteConfig } from 'vue-router';

export const adminRoutes: Array<RouteConfig> = [
  {
    path: 'settings',
    name: 'Settings',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Settings/Settings.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'harvest-load-calculation',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/layouts/FarmFilteredLayout.vue'
      ),
    children: [
      {
        path: '',
        name: 'HarvestLoadCalculation',
        component: () =>
          import(
            /* webpackChunkName: "general" */ '@/views/pages/Settings/HarvestLoadCalculation.vue'
          ),
        meta: {
          requiresAuth: true,
          allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
        }
      }
    ]
  },
  {
    path: 'settings',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/layouts/FarmFilteredLayout.vue'
      ),
    children: [
      {
        path: 'blocks',
        name: 'BlockList',
        component: () =>
          import(
            /* webpackChunkName: "general" */ '@/views/pages/Settings/BlockList.vue'
          ),
        meta: {
          requiresAuth: true,
          allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
        }
      },
      {
        path: 'blocks/assignCorrectionFactor',
        name: 'AssignCorrectionFactor',
        component: () =>
          import(
            /* webpackChunkName: "general" */ '@/components/Forms/Blocks/AssignCFForm.vue'
          ),
        meta: {
          requiresAuth: true,
          allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
        }
      },
      {
        path: 'blocks/assignCrop',
        name: 'AssignCrop',
        component: () =>
          import(
            /* webpackChunkName: "general" */ '@/components/Forms/Blocks/AssignCropForm.vue'
          ),
        meta: {
          requiresAuth: true,
          allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
        }
      },
      {
        path: 'blocks/add',
        name: 'AddBlock',
        component: () =>
          import(
            /* webpackChunkName: "general" */ '@/components/Forms/Blocks/BlockForm.vue'
          ),
        meta: {
          requiresAuth: true,
          allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
        }
      },
      {
        path: 'blocks/:blockId/edit',
        name: 'EditBlock',
        component: () =>
          import(
            /* webpackChunkName: "general" */ '@/components/Forms/Blocks/BlockForm.vue'
          ),
        meta: {
          requiresAuth: true,
          allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
        }
      },
      {
        path: 'blocks/:blockId/sensor',
        name: 'BlockSensor',
        component: () =>
          import(
            /* webpackChunkName: "general" */ '@/components/Forms/Blocks/BlockSensorForm.vue'
          ),
        meta: {
          requiresAuth: true,
          allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
        }
      },
      {
        path: 'configuration/harvest-load',
        name: 'HarvestLoadConfiguration',
        component: () =>
          import(
            /* webpackChunkName: "general" */ '@/views/pages/Settings/HarvestLoadConfiguration.vue'
          ),
        meta: {
          requiresAuth: true,
          allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
        }
      },
      {
        path: 'configuration/dry-matter',
        name: 'DryMatterConfiguration',
        component: () =>
          import(
            /* webpackChunkName: "general" */ '@/views/pages/Settings/DryMatterConfiguration.vue'
          ),
        meta: {
          requiresAuth: true,
          allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
        }
      },
      {
        path: 'configuration/seasons',
        name: 'SeasonConfiguration',
        component: () =>
          import(
            /* webpackChunkName: "general" */ '@/views/pages/Settings/SeasonList.vue'
          ),
        meta: {
          requiresAuth: true,
          allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
        }
      },
      {
        path: 'configuration/seasons/add/:cropId',
        name: 'AddSeason',
        component: () =>
          import(
            /* webpackChunkName: "general" */ '@/views/pages/Settings/SeasonConfiguration.vue'
          ),
        meta: {
          requiresAuth: true,
          allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
        }
      },
      {
        path: 'configuration/seasons/:seasonId/edit/:cropId',
        name: 'EditSeason',
        component: () =>
          import(
            /* webpackChunkName: "general" */ '@/views/pages/Settings/SeasonConfiguration.vue'
          ),
        meta: {
          requiresAuth: true,
          allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
        }
      },
      {
        path: 'diary',
        name: 'DiaryList',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@/views/pages/Settings/DiaryList.vue'
          ),
        meta: {
          requiresAuth: true,
          allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
        }
      },
      {
        path: 'diary/add',
        name: 'AddDiary',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@/components/Forms/DiaryForm.vue'
          ),
        meta: {
          requiresAuth: true,
          allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
        }
      },
      {
        path: 'diary/edit/:diaryId',
        name: 'EditDiary',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '@/components/Forms/DiaryForm.vue'
          ),
        meta: {
          requiresAuth: true,
          allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
        }
      }
    ]
  },
  {
    path: 'settings/configuration',
    name: 'Configuration',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Settings/ConfigurationPage.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'settings/configuration/smtp',
    name: 'SMTPConfiguration',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Settings/SMTPConfiguration.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN]
    }
  },
  {
    path: 'settings/invite-admin',
    name: 'InviteAdmin',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Settings/InviteAdmin.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN]
    }
  },
  {
    path: 'settings/invite-owner',
    name: 'InviteOwner',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Settings/InviteOwner.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN]
    }
  },
  {
    path: 'settings/invite-manager',
    name: 'InviteManager',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Settings/InviteManager.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER]
    }
  },
  {
    path: 'settings/invite-consultant',
    name: 'InviteConsultant',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Settings/InviteConsultant.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER]
    }
  },
  {
    path: 'settings/invite-marketer',
    name: 'InviteMarketer',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Settings/InviteMarketer.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER]
    }
  },
  {
    path: 'settings/invitations',
    name: 'InvitationList',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Settings/InvitationList.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER]
    }
  },
  {
    path: 'settings/farms',
    name: 'FarmList',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Settings/FarmList.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'settings/farms/add',
    name: 'AddFarm',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/components/Forms/Farms/FarmForm.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'settings/farms/:farmId/edit',
    name: 'EditFarm',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/components/Forms/Farms/FarmForm.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  // {
  //   path: 'settings/farms/:farmId/sensors',
  //   name: 'FarmSensorList',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "general" */ '@/views/pages/Settings/FarmSensorList.vue'
  //     ),
  //   meta: {
  //     requiresAuth: true,
  //     allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
  //   }
  // },
  // {
  //   path: 'settings/farms/:farmId/sensors/add',
  //   name: 'AddFarmSensor',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "general" */ '@/components/Forms/Farms/FarmSensorForm.vue'
  //     ),
  //   meta: {
  //     requiresAuth: true,
  //     allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
  //   }
  // },
  {
    path: 'settings/sensorLocations',
    name: 'SensorLocationList',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Settings/SensorLocationList.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'settings/sensorLocations/add',
    name: 'AddSensorLocation',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/components/Forms/Sensors/SensorLocationForm.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'settings/sensorLocations/:sensorLocationId/edit',
    name: 'EditSensorLocation',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/components/Forms/Sensors/SensorLocationForm.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'settings/sensorLocations/:sensorLocationId/profile',
    name: 'SensorLocationSensorProfile',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Settings/SensorProfile.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'settings/sensorLocations/:sensorLocationId/sensor',
    name: 'AttachSensorLocation',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/components/Forms/Sensors/AttachSensorLocationForm.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'settings/crops',
    name: 'CropList',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Settings/CropList.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'settings/crops/add',
    name: 'AddCrop',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/components/Forms/CropForm.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'settings/crops/:cropId/edit',
    name: 'EditCrop',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/components/Forms/CropForm.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'settings/cropTypes',
    name: 'CropTypeList',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Settings/CropTypeList.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'settings/cropTypes/add',
    name: 'AddCropType',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/components/Forms/CropTypeForm.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'settings/cropTypes/:cropTypeId/edit',
    name: 'EditCropType',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/components/Forms/CropTypeForm.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'settings/cropVarieties',
    name: 'CropVarietyList',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Settings/CropVarietyList.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'settings/cropVarieties/add',
    name: 'AddCropVariety',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/components/Forms/CropVarietyForm.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'settings/cropVarieties/:cropVarietyId/edit',
    name: 'EditCropVariety',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/components/Forms/CropVarietyForm.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  // {
  //   path: 'flowerEvents',
  //   name: 'FlowerEventList',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "general" */ '@/views/pages/Settings/FlowerEventList.vue'
  //     ),
  //   meta: {
  //     requiresAuth: true,
  //     allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
  //   }
  // },
  // {
  //   path: 'flowerEvents/add',
  //   name: 'AddFlowerEvent',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "general" */ '@/components/Forms/Flowers/FlowerEventForm.vue'
  //     ),
  //   meta: {
  //     requiresAuth: true,
  //     allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
  //   }
  // },
  // {
  //   path: 'flowerEvents/:flowerEventId/edit',
  //   name: 'EditFlowerEvent',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "general" */ '@/components/Forms/Flowers/FlowerEventForm.vue'
  //     ),
  //   meta: {
  //     requiresAuth: true,
  //     allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
  //   }
  // },
  // {
  //   path: 'flowerEvents/:flowerEventIds/edit',
  //   name: 'EditMultipleFlowerEvent',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "general" */ '@/components/Forms/Flowers/FlowerEventForm.vue'
  //     ),
  //   meta: {
  //     requiresAuth: true,
  //     allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
  //   }
  // },
  {
    path: 'settings/admin/users',
    name: 'AdminUsers',
    component: () =>
      import(/* webpackChunkName: "admin" */ '@/views/pages/Admin/Users.vue'),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'settings/admin/users/:userId',
    name: 'AdminUserDetail',
    component: () =>
      import(
        /* webpackChunkName: "admin" */ '@/views/pages/Admin/UserDetail.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'upload/fruits_count',
    name: 'UploadFruitsCount',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/UploadPage/FruitCount.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'upload/fruits_count/image',
    name: 'UploadFruitsCountImage',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/components/Forms/FruitCountImageUploadForm.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'upload/flowering',
    name: 'UploadFlowering',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/UploadPage/Flowering.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'upload/flowering/image',
    name: 'UploadFloweringImage',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/components/Forms/FloweringImageUploadForm.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'upload/fruit_size/image',
    name: 'UploadFruitSizeImage',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/components/Forms/FruitSizeImageUploadForm.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'send_categorical_email',
    name: 'SendCategoricalEmail',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Admin/SendCategoricalEmail.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN]
    }
  },
  {
    path: 'settings/send_email/:userId',
    name: 'SendUserEmail',
    component: () =>
      import(
        /* webpackChunkName: "general" */ '@/views/pages/Admin/SendEmail.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'settings/blogs',
    name: 'BlogList',
    component: () =>
      import(
        /* webpackChunkName: "admin" */ '@/views/pages/Settings/BlogSettingsList.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'settings/blogs/add',
    name: 'AddBlog',
    component: () =>
      import(
        /* webpackChunkName: "admin" */ '@/views/pages/Settings/BlogForm.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'settings/blogs/edit/:id',
    name: 'EditBlog',
    component: () =>
      import(
        /* webpackChunkName: "admin" */ '@/views/pages/Settings/BlogForm.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN, UserTypes.OWNER, UserTypes.MANAGER]
    }
  },
  {
    path: 'sensors-data-download',
    name: 'SensorDataDownload',
    component: () =>
      import(
        /* webpackChunkName: "admin" */ '@/views/pages/Admin/SensorHistoryDownload.vue'
      ),
    meta: {
      requiresAuth: true,
      allowedRoles: [UserTypes.ADMIN]
    }
  }
];
