import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import { JwtStore } from '@/store/modules';
import { WaitUntilRefreshed, roleChecker } from '@/utils/jwt';
import { authRoutes } from '@/router/auth';
import { generalRoutes } from '@/router/general';
import { adminRoutes } from '@/router/admin';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '',
    name: 'LandingPage',
    component: () =>
      import(
        /* webpackChunkName: "landing" */ '@/views/pages/LandingPageNew.vue'
      )
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/pages/Login.vue')
  },
  {
    path: '',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/layouts/MainLayout.vue'),
    children: [...authRoutes, ...generalRoutes, ...adminRoutes]
  },
  {
    path: '*',
    name: 'NotFound',
    // route level code-splitting
    // this generates a separate chunk (general.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "general" */ '@/views/pages/NotFound.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes
});

export default router;

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
  // eslint-disable-next-line
  if (to.matched.some((record: any) => record.meta.requiresAuth)) {
    // Wait if JWT is refreshing
    await WaitUntilRefreshed();
    if (JwtStore.loggedIn) {
      if (to.meta) {
        if (to.meta.allowedRoles && !roleChecker(to.meta.allowedRoles)) {
          next({
            name: 'LandingPage'
          });
        }
        next();
      } else {
        next();
      }
    } else {
      next({
        name: 'LandingPage'
      });
    }
  } else if (to.name == 'Login') {
    // Wait if JWT is refreshing
    await WaitUntilRefreshed();
    if (JwtStore.loggedIn) {
      next({ name: 'Home' });
    } else {
      next();
    }
  } else {
    next();
  }
});
